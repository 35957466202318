import { useQueryClient } from '@tanstack/react-query';
import {
  useEffect,
  Fragment,
} from 'react';

import { useNotification } from '@oncore/ui/Notification';

const QueryMutationHandler = () => {
  const queryClient = useQueryClient();

  const mutationCache = queryClient.getMutationCache();

  const queryCache = queryClient.getQueryCache();

  const {
    enqueueNotification,
  } = useNotification();

  useEffect(() => {
    const unsubscribe = mutationCache.subscribe((event) => {

      if (!event.mutation || event.type !== 'updated') {
        return;
      }

      const errorMeta = event.mutation.meta?.notification?.error;

      if (event.action.type === 'error' && errorMeta) {

        enqueueNotification({
          variant: 'warning',
          title: errorMeta.title,
          message: errorMeta.message,
        });
      }

      const successMeta = event.mutation.meta?.notification?.success;

      if (event.action.type === 'success' && successMeta) {
        enqueueNotification({
          variant: 'success',
          title: successMeta.title,
          message: successMeta.message,
        });
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const unsubscribe = queryCache.subscribe((event) => {

      if (event.type === 'updated' && event.action.type === 'error') {
        const errorMeta = event.query.meta?.notification?.error;

        const params = {
          variant: 'warning' as const,
          title: 'Failed to retrieve data',
          message: 'Please refresh the page or if the error persists, try again later.',
        };

        if (errorMeta?.title) {
          params.title = errorMeta.title;
          params.message = errorMeta.message || '';
        }

        enqueueNotification(params);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Fragment/>
  );
};

export default QueryMutationHandler;
