import {
  LoadingScreen,
  WizardPage,
  WizardSuccessPage,
  WizardErrorPage,
  isExhaustive
} from '@oncore/shared';
import usePasswordReset from './usePasswordReset';
import { PasswordChangeStepThree } from '../PasswordChange';
import PasswordResetWidget from './PasswordResetWidget';
import PasswordResetSent from './PasswordResetSent';
import { useEffect, useState } from 'react';
import { links } from '../../components/chrome';
import { useNavigate } from 'react-router-dom';

export type Props = {
  children?: never;
};

type State = 'loading' | 'lodge' | 'sent' | 'finalise' | 'success' | 'error';

const PasswordResetPage = (_: Props) => {
  const {
    lodged,
    ticket,
    error,
    success,
    onLodge,
    onVerify,
    onFinalise
  } = usePasswordReset();

  const navigate = useNavigate();

  const [state, setState] = useState<State>(ticket ? 'loading' : 'lodge');

  const handleCancel = () => {
    navigate(links.user.profile.security);
  };

  useEffect(() => {
    if (ticket) {
      onVerify(ticket)
        .then(x => {
          console.debug(x);
          setState('finalise');
        }, x => {
          console.warn(x);
          setState('error');
        });
    }
  }, [ticket]);

  useEffect(() => {
    if (lodged) {
      setState('sent');
    }
  }, [lodged]);

  useEffect(() => {
    if (success) {
      setState('success');
    }
  }, [success]);

  const renderContent = function () {
    switch (state) {
      case 'loading':
        return (<LoadingScreen/>);
      case 'lodge':
        return (<PasswordResetWidget onLodge={onLodge} />);
      case 'sent':
        return (<PasswordResetSent />);
      case 'finalise':
        return (<PasswordChangeStepThree
          error={error}
          onChange={(newPassword: string) => onFinalise(ticket!, newPassword)}
          onCancel={handleCancel}
        />);
      case 'success':
        return (<WizardSuccessPage
          title="Reset Password"
          secondTitle="Your new password is set"
          targetUrl={links.login} />);
      case 'error':
        return (<WizardErrorPage
          title="Reset Password"
          secondTitle="Something went wrong!"
          targetUrl={links.login} />);
      default:
        return isExhaustive(state);
    }
  };

  return (
    <WizardPage title="Reset Password">
      {renderContent()}
    </WizardPage>
  );
};

export default PasswordResetPage;
