import { FormikProps } from 'formik';
import get from 'lodash/get';

type Props<T> = {
  formik: FormikProps<T>;
};

export function useFormikHelpers<T>(props: Props<T>) {
  const { formik } = props;

  const getHelperText = (path: string): string | undefined => {
    return (get(formik.touched, path) && get(formik.errors, path)) || undefined;
  };

  const getError = (path: string): boolean => {
    return get(formik.touched, path) && !!get(formik.errors, path);
  };

  const updateFieldValue = (field: string, value: any) => {
    formik.setFieldValue(field, value);
    formik.setFieldTouched(field, true);
  };

  return {
    getHelperText,
    getError,
    updateFieldValue
  };
}
