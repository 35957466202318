import * as yup from 'yup';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { Link, Typography } from '@mui/material';
import { TextField, WizardHeader, TrackedButton } from '@oncore/shared';
import { links } from 'src/components/chrome';
import { ErrorResponse } from './usePasswordReset';

const Root = styled.div`
  display: flex;
  position: relative;
  flex-flow: column;
  align-items: center;
  max-width: 350px;
  flex-basis: 100%;
  margin: 0 24px;

  > * {
    margin: 8px 0;
  }

  > svg {
    position: absolute;
    top: 35%;
    left: 18%;
    bottom: -25%;
    right: -50%;
    color: white;
    fill: white;
  }

  > img {
    z-index: 1;
    margin: 48px 0;
  }

  > a {
    z-index: 1;
    margin-top: 16px;
    align-self: flex-start;
  }

  > button {
    z-index: 1;
    margin-top: 16px;
    align-self: flex-end;
  }

  .MuiInputBase-root {
    background-color: #E9EDF1 !important;
  }
`;

export type Values = {
  email: string
};

export type Props = {
  onLodge: (email: string) => Promise<ErrorResponse | null>;
  children?: never;
};

const PasswordResetWidget = (props: Props) => {

  const {
    onLodge
  } = props;

  const schema: yup.Schema<Values> = yup.object({
    email: yup.string()
      .label('Email')
      .defined()
      .required('Your login email is required')
      .email(),
  });

  const formik = useFormik<Values>({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      email: ''
    },
    validationSchema: schema,
    onSubmit: (values: Values) => onLodge(values.email)
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Root>
        <WizardHeader title="Reset Password" />
        <Typography variant="body1">Enter your registered email, and we’ll send you instructions for resetting your password.</Typography>
        <TextField
          label="Email"
          id="email"
          name="email"
          value={formik.values.email}
          onCommit={formik.handleChange}
          onBlur={() => formik.setFieldTouched('email', true, true)}
          helperText={formik.touched.email ? formik.errors.email : undefined}
          error={formik.touched.email && !!formik.errors.email}
          disabled={formik.isSubmitting}
          required
        />
        <Link
          variant="body2"
          underline="none"
          href={links.login}
        >Return to login</Link>
        <TrackedButton
          trackedName="Reset"
          variant="contained"
          disabled={formik.isSubmitting}
          onClick={formik.submitForm}
        >Reset</TrackedButton>
      </Root>
    </form>
  );
};

export default PasswordResetWidget;
