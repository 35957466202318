import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from '@oncore/ui/Layout';
import ChromeTopBar from './ChromeTopBar';
import ChromeSideBar from './ChromeSideBar';
import { OncoreFlexLayout } from 'src/components/chrome';
import { getAppStateSelector } from 'src/store/selectors';

const Chrome = () => {
  const { isUntitledUI } = useSelector(getAppStateSelector());

  if (isUntitledUI) {
    return (
      <Layout
        product="omp"
        topbar={<ChromeTopBar />}
        sidebar={<ChromeSideBar />}
        content={<Outlet />}
      />
    );
  }

  return (
    <OncoreFlexLayout/>
  );
};

export default Chrome;
