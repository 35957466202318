import React from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import { StyledLinearProgress } from '@oncore/shared';
import ScrollReset from '../ScrollReset';
import OncoreErrorBoundary from './OncoreErrorBoundary';
import Chrome from 'src/spike/Chrome';
import styled from 'styled-components';

const StyledLinearProgressContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const innerChildRouteFor = (path: string, element: JSX.Element, children?: RouteObject[]): RouteObject => ({
  path,
  element: (
    <React.Suspense fallback={<StyledLinearProgress />}>
      <OncoreErrorBoundary>
        {element}
      </OncoreErrorBoundary>
    </React.Suspense>
  ),
  children
});

export const layoutFor = (element: JSX.Element, children?: RouteObject[]): RouteObject => ({
  element: (
    <React.Suspense fallback={<StyledLinearProgress />}>
      <OncoreErrorBoundary>
        {element}
      </OncoreErrorBoundary>
    </React.Suspense>
  ),
  children
});

const ChromeChildrenFallBack = () => {
  return (
    <StyledLinearProgressContainer >
      <StyledLinearProgress />
    </StyledLinearProgressContainer>
  );
};

export const childRouteFor = (path: string, element: JSX.Element, children?: RouteObject[], isNonChrome?:boolean): RouteObject => ({
  path,
  element: (
    <React.Suspense fallback={<StyledLinearProgress />}>
      <ScrollReset />
      <OncoreErrorBoundary>
        {isNonChrome ? <Outlet/> : <Chrome />}
      </OncoreErrorBoundary>
    </React.Suspense>
  ),
  children: [...(children || []), {
    index: true,
    element: (
      <React.Suspense fallback={<ChromeChildrenFallBack />}>
        <OncoreErrorBoundary>
          {element}
        </OncoreErrorBoundary>
      </React.Suspense>
    )
  }]
});
