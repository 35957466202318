import {
  AnalyticsEvents,
  axios,
  useAnalyticsUpdater
} from '@oncore/shared';
import { useEffect, useState } from 'react';

export type ErrorResponse = {
  message: string
};

export const usePasswordReset = (): {
  lodged: boolean;
  ticket: string | null;
  error: string | null;
  success: boolean;
  onLodge: (email: string) => Promise<ErrorResponse | null>;
  onVerify: (ticket: string) => Promise<ErrorResponse | null>;
  onFinalise: (ticket: string, password: string) => Promise<ErrorResponse | null>;
} => {

  const mounted = new AbortController();

  const [lodged, setLodged] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const sendAnalyticsAttempted = useAnalyticsUpdater(AnalyticsEvents.PASSWORD_RESET_ATTEMPTED);

  const sendAnalyticsCompleted = useAnalyticsUpdater(AnalyticsEvents.PASSWORD_RESET_COMPLETED);

  useEffect(() => {
    sendAnalyticsAttempted();
    return () => mounted.abort();
  }, []);

  const onLodge = (email: string) : Promise<ErrorResponse | null> => {
    return axios.post('/auth/reset-password/lodge', {
      email
    }, {
      signal: mounted.signal
    }).then(() => {
      setLodged(true);
      setError(null);
    }, x => {
      setError(x.message);
      return x;
    });
  };

  const onVerify = (ticket: string) : Promise<ErrorResponse | null> => {
    return axios.post('/auth/reset-password/verify', {
      ticket
    }, {
      signal: mounted.signal
    }).catch(x => {
      setError(x.message);
      return x;
    });
  };

  const finaliseOnSuccess = () => {
    sendAnalyticsCompleted();
    setSuccess(true);
  };

  const onFinalise = (ticket: string, password: string) : Promise<ErrorResponse | null> => {
    return axios.post('/auth/reset-password/finalise', {
      ticket,
      password
    }, {
      signal: mounted.signal
    }).then(() => finaliseOnSuccess(), x => {
      setError(x.message);
      return x;
    });
  };

  const params = new URLSearchParams(window.location.search);
  const ticket = params.get('t');

  return {
    lodged,
    ticket,
    error,
    success,
    onLodge,
    onVerify,
    onFinalise
  };
};

export default usePasswordReset;
