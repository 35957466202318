import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'nprogress/nprogress.css';
import 'src/assets/css/prism.css';
import { createRoot } from 'react-dom/client';
import { EnvironmentProvider } from '@oncore/shared';
import { SettingsProvider } from 'src/contexts/SettingsContext';
import App from 'src/App';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
      <EnvironmentProvider>
        <SettingsProvider>
          <App />
        </SettingsProvider>
      </EnvironmentProvider>
  );
}
