import axios, { AxiosRequestConfig, isAxiosError } from 'axios';
import { ProfileEmailQuery } from 'src/__generated__/ProfileEmailQuery.graphql';
import { profileEmailQuery } from './ProfileEmail';
import useQuery from './useQuery';
import { useEnvironment } from '@oncore/shared';

type AccessTokenResponse = {
  access_token: string;
};

type LoginOptions = {
  domain: string;
  tenant: string;
  clientID: string;
  username: string;
  password: string;
  signal: AbortSignal;
};

type VerifyOptions = {
  domain: string;
  token: string;
  clientID: string;
  code: string;
  recovery: boolean;
  signal: AbortSignal;
};

export type VerifyPasswordResult = {
  state: 'success';
  token: string;
} | {
  state: 'mfa_required';
  token: string;
};

const login = async (options: LoginOptions) : Promise<AccessTokenResponse> => {
  // we use dedicated axios instance to ensure
  // no unnecessary information is sent, which may include
  // an existing authorization token headers, etc.
  const http = axios.create();

  const url = `https://${options.domain}/oauth/token`;
  const data = {
    audience: `https://${options.tenant}.au.auth0.com/mfa/`,
    client_id: options.clientID,
    grant_type: 'http://auth0.com/oauth/grant-type/password-realm',
    scope: 'read:authenticators remove:authenticators',
    realm: 'OncoreFlex',
    username: options.username,
    password: options.password,
  };
  const config = <AxiosRequestConfig>{
    signal: options.signal
  };

  const response = await http.post<AccessTokenResponse>(url, data, config);

  return response.data;
};

const verify = async (options: VerifyOptions) : Promise<AccessTokenResponse> => {
  // we use dedicated axios instance to ensure
  // no unnecessary information is sent, which may include
  // an existing authorization token headers, etc.
  const http = axios.create();

  const data = new URLSearchParams();
  data.append('client_id', options.clientID);
  data.append('mfa_token', options.token);
  if (options.recovery) {
    data.append('grant_type', 'http://auth0.com/oauth/grant-type/mfa-recovery-code');
    data.append('recovery_code', options.code);
  } else {
    data.append('grant_type', 'http://auth0.com/oauth/grant-type/mfa-otp');
    data.append('otp', options.code);
  }
  const url = `https://${options.domain}/oauth/token`;
  const config = <AxiosRequestConfig>{
    signal: options.signal
  };

  const response = await http.post<AccessTokenResponse>(url, data, config);

  return response.data;
};

export const useSecurity = () => {
  const config = useEnvironment();

  const {
    auth: settings
  } = config;

  const mounted = new AbortController();

  if (!settings) {
    throw new Error('[usePasswordChange] Environment settings are not available');
  }

  const {
    domain = '',
    tenant = '',
    clientID = '',
  } = settings;

  const fetchEmail = useQuery<ProfileEmailQuery>(
    profileEmailQuery,
    {}
  );

  const getEmail = (): Promise<string> => new Promise((resolve, reject) => {
    fetchEmail
      .fetch()
      .then((x) => resolve(x?.user.profile.email || ''), reject);
  });

  const onLogin = (email: string, password: string) => new Promise<VerifyPasswordResult>((resolve, reject) => {

    login({
      domain,
      tenant,
      clientID,
      username: email,
      password,
      signal: mounted.signal
    })
      .then((x) => {
        resolve({
          state: 'success',
          token: x.access_token
        });
      })
      .catch(x => {
        if (isAxiosError(x)) {
          switch (x.response?.data?.error) {
            case 'mfa_required':
              resolve({
                state: 'mfa_required',
                token: x.response.data.mfa_token
              });
              break;

            default:
              if (x.response?.data.error_description) {
                reject(
                  new Error(x.response.data.error_description)
                );
              }
              break;
          }
        }
        reject(x);
      });
  });
  
  const onVerifyMFA = (token: string, otp: string, recovery: boolean) => new Promise<string>((resolve, reject) => {
    verify({
      domain,
      token,
      clientID,
      code: otp,
      recovery,
      signal: mounted.signal
    })
      .then((response) => {
        resolve(response.access_token);
      }, reject);
  });

  return {
    getEmail,
    onLogin,
    onVerifyMFA,
  };
};
