import jwtDecode, { JwtPayload } from 'jwt-decode';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AgencyFeature,
  AgencyPermission,
  axios,
  EnterpriseFeature,
  EnterprisePermission,
  Product,
  UserFeature,
  UserPermission
} from '@oncore/shared';
import { OncoreFlexContextQuery$data as ContextsResponse } from 'src/__generated__/OncoreFlexContextQuery.graphql';

export type UserContext = {
  context: 'user';
  product: Product.Freedom;
  permissions: UserPermission[];
  features: UserFeature[];
};

export type EnterpriseContext = {
  context: 'enterprise';
  product: Product.Simplexity;
  id: string;
  displayName: string;
  countryCode?: string;
  permissions: EnterprisePermission[];
  features: EnterpriseFeature[];
  groups: {
    id: string;
    displayName: string;
    permissions: EnterprisePermission[];
  }[];
};

export type AgencyContext = {
  context: 'agency';
  product: Product.Connect;
  id: string;
  displayName: string;
  countryCode?: string;
  permissions: AgencyPermission[];
  features: AgencyFeature[];
  groups: {
    id: string;
    displayName: string;
    permissions: AgencyPermission[];
  }[];
};

type LoadingContext = {
  context: 'loading';
};

type EmptyContext = {
  context: 'empty';
};

type ErrorContext = {
  context: 'error';
  error: Error;
};

export type Context =
  | AgencyContext
  | EnterpriseContext
  | UserContext;

export function isUserContext(context: Context): context is UserContext {
  return context.context === 'user';
}

export function isEnterpriseContext(context: Context): context is EnterpriseContext {
  return context.context === 'enterprise';
}

export function isAgencyContext(context: Context): context is EnterpriseContext {
  return context.context === 'agency';
}

export function isTenantContext(context: Context): context is (EnterpriseContext | AgencyContext) {
  return context.context !== 'user';
}

type OncoreFlexBranding = {
  smallLogoUrl?: string | null;
  largeLogoUrl?: string | null;
};

export type HolidayBanner = {
  message: string;
  startDate?: string | null;
  endDate?: string | null;
};

export type AppState = {
  userID?: string;
  displayName: string;
  emailAddress: string;
  currentContext: Context | LoadingContext | EmptyContext | ErrorContext;
  contexts: Context[];
  token: string | null;
  isMenuOpen: boolean;
  isContextStale: boolean;
  isTokenChecked: boolean;
  isMultiFactorAuthAllowed: boolean;
  branding: OncoreFlexBranding;
  isBankingEnabled: boolean;
  isCompanyDetailsEnabled: boolean;
  isUntitledUI: boolean;
  holidayBanner? : HolidayBanner;
  firstName: string;
};

const initialState: AppState = {
  displayName: '',
  emailAddress: '',
  token: null,
  currentContext: { context: 'loading' },
  contexts: [],
  isMenuOpen: false,
  isContextStale: true,
  isTokenChecked: false,
  isMultiFactorAuthAllowed: false,
  branding: {},
  isBankingEnabled: false,
  isCompanyDetailsEnabled: false,
  isUntitledUI: false,
  firstName: ''
};

type SwitchContextPayload =
  | { context: 'user' }
  | { context: 'enterprise', tenantId: string }
  | { context: 'agency', tenantId: string };

const TOKEN_STORE_KEY = 'token';

const userSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      axios.defaults.headers.common.Authorization = `Bearer ${action.payload}`;
      localStorage.setItem(TOKEN_STORE_KEY, action.payload);
      state.isContextStale = state.token !== action.payload;
      state.isTokenChecked = true;
      state.token = action.payload;
      state.userID = jwtDecode<JwtPayload>(action.payload).sub;
    },

    logout: () => {
      localStorage.removeItem(TOKEN_STORE_KEY);
      delete axios.defaults.headers.common.Authorization;
      return {
        ...initialState,
        isTokenChecked: true
      };
    },

    checkToken: (state) => {
      state.isTokenChecked = false;
    },

    setMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.isMenuOpen = action.payload;
    },

    setErrorContext: (state, action: PayloadAction<Error>) => {
      state.currentContext = {
        context: 'error',
        error: action.payload,
      };
    },

    setContexts: (state, action: PayloadAction<ContextsResponse>) => {
      const {
        features,
        permissions,
        memberships,
        displayName,
        profile,
      } = action.payload.user;
      state.displayName = displayName;
      if (
        features.length === 0 &&
        permissions.length === 0 &&
        memberships.length === 0
      ) {
        state.currentContext = { context: 'empty' };
      }
      const contexts = [];
      if (permissions.length > 0) {
        contexts.push({
          context: 'user',
          product: 'freedom',
          permissions,
          features,
        } as UserContext);
      }
      for (const x of memberships) {
        switch (x.type) {
          case 'AgencyRoot':
            contexts.push({
              context: 'agency',
              product: 'connect',
              id: x.id,
              displayName: x.displayName,
              countryCode: 'AU',
              permissions: x.agencyPermissions,
              features: x.agencyFeatures,
              groups: [
                ...x.agencyMemberships.map((g) => ({
                  id: g.group.id,
                  displayName: g.group.displayName,
                  permissions: g.permissions,
                }))],
            } as AgencyContext);
            break;
          case 'EnterpriseRoot':
            contexts.push({
              context: 'enterprise',
              product: 'simplexity',
              id: x.id,
              displayName: x.displayName,
              countryCode: 'AU',
              permissions: x.enterprisePermissions,
              features: x.enterpriseFeatures,
              groups: [
                ...x.enterpriseMemberships.map((g) => ({
                  id: g.group.id,
                  displayName: g.group.displayName,
                  permissions: g.permissions,
                }))],
            } as EnterpriseContext);
            break;
        }
      }
      // Check last used context
      let context: Context | undefined = undefined;
      const lastUsedContextData = window.localStorage.getItem(`${state.userID}|context`);
      const lastUsedContext: SwitchContextPayload = lastUsedContextData != null ? JSON.parse(lastUsedContextData) : null;
      switch (lastUsedContext?.context) {
        case 'user':
          const userContext = contexts.find((x) => x.context === 'user');
          if (userContext !== undefined) {
            context = userContext;
          }
          break;
        case 'agency':
          const agencyContext = contexts.find((x) => x.context === 'agency' && x.id === lastUsedContext.tenantId);
          if (agencyContext?.context === 'agency') {
            context = agencyContext;
          }
          break;
        case 'enterprise':
          const enterpriseContext = contexts.find((x) => x.context === 'enterprise' && x.id === lastUsedContext.tenantId);
          if (enterpriseContext?.context === 'enterprise') {
            context = enterpriseContext;
          }
          break;
        default:
          context = contexts[0];
      }
      // Set up state
      state.contexts = contexts;
      state.currentContext = context || contexts[0];
      state.isContextStale = false;
      state.isMultiFactorAuthAllowed = features.includes(UserFeature.MFA);
      state.isBankingEnabled = features.includes(UserFeature.Banking);
      state.isCompanyDetailsEnabled = features.includes(UserFeature.CompanyDetails);
      state.isUntitledUI = features.includes(UserFeature.UntitledUI);
      state.holidayBanner = action.payload.holidayBanner !== null
        ? action.payload.holidayBanner
        : undefined;
      state.emailAddress = profile.email || '';
      state.firstName = profile.firstName || '';
    },

    switchContext: (state, action: PayloadAction<SwitchContextPayload>) => {
      let nextContext: Context | undefined;
      switch (action.payload.context) {
        case 'user':
          nextContext = state.contexts.find((x) => x.context === 'user');
          break;
        case 'agency':
        case 'enterprise':
          nextContext = state.contexts.find((x) =>
            x.context === action.payload.context &&
            // @ts-ignore
            x.id === action.payload.tenantId
          );
          break;
      }
      if (nextContext) {
        window.localStorage.setItem(`${state.userID}|context`, JSON.stringify(action.payload));
        state.currentContext = nextContext;
      }
    },
  }
});

export const {
  setToken,
  logout,
  checkToken,
  setMenuOpen,
  setContexts,
  switchContext,
  setErrorContext,
} = userSlice.actions;

export default userSlice.reducer;
