import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { LoadingScreen, StyledLinearProgress, useSetAnalyticsUserProperties } from '@oncore/shared';
import { switchContext, isTenantContext } from 'src/store/appSlice';
import { useDispatch, useSelector } from 'src/hooks';

const NotFoundView = React.lazy(() => import('src/spike/Notice/NotFoundErrorNotice'));

export type Props = {
  children?: never;
};

export const TenantContextSwitch = (_: Props) => {

  const { tenantID = '' } = useParams();

  const { contexts, currentContext } = useSelector((s) => s.app);

  const dispatch = useDispatch();

  const setAnalyticsUserProperties = useSetAnalyticsUserProperties();

  const desired = contexts
    .filter(isTenantContext)
    .find(x => x.id === tenantID);

  useEffect(() => {
    if (!desired) {
      console.error('Unknown context');
      return;
    }

    if (currentContext.context !== desired.context || currentContext.id !== desired.id) {
      dispatch(switchContext({
        context: desired.context,
        tenantId: desired.id
      }));
    }
  }, [desired?.context, desired?.id]);

  useEffect(() => {
    if (currentContext.context === 'agency' || currentContext.context === 'enterprise') {
      setAnalyticsUserProperties({
        tenant: currentContext.displayName,
        context: currentContext.context,
        features: currentContext.features,
        permissions: currentContext.permissions
      });
    } else if (currentContext.context === 'user') {
      setAnalyticsUserProperties({
        tenant: 'none',
        context: currentContext.context,
        features: currentContext.features,
        permissions: currentContext.permissions
      });
    }
  }, [currentContext]);

  return desired ?
    currentContext.context === desired.context && currentContext.id === desired.id
      ? <Outlet />
      : <LoadingScreen />
    : (
      <React.Suspense fallback={<StyledLinearProgress />}>
        <NotFoundView />
      </React.Suspense>
    );
};

export default TenantContextSwitch;
