import * as yup from 'yup';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { Alert, Typography } from '@mui/material';
import { TextField, WizardHeader, WizardStepActions } from '@oncore/shared';
import { useFormikHelpers } from 'src/hooks';

const RootForm = styled.form`
  max-width: 450px;
  flex-basis: 100%;
`;

const Root = styled.div`
  display: flex;
  position: relative;
  flex-flow: column;
  align-items: center;
  max-width: 350px;
  flex-basis: 100%;
  margin: 0 24px;

  > * {
    margin: 8px 0;
  }

  > svg {
    position: absolute;
    top: 35%;
    left: 18%;
    bottom: -25%;
    right: -50%;
    color: white;
    fill: white;
  }

  > img {
    z-index: 1;
    margin: 48px 0;
  }

  > h4 {
    z-index: 1;
    align-self: center;
    margin-bottom: 16px;
  }

  > button {
    z-index: 1;
    margin-top: 16px;
    align-self: flex-end;
  }

  > p {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .MuiInputBase-root {
    background-color: #E9EDF1 !important;
  }

  .MuiPaper-root {
    z-index: 1;
    align-self: stretch;
  }
`;

export type State = {
  newPassword1: string;
  newPassword2: string;
};

export type Props = {
  error: string | null;
  onChange: (password: string) => void;
  onCancel: () => void;
  children?: never;
};

const PasswordChangeStepThree = (props: Props) => {
  const {
    error,
    onChange,
    onCancel,
  } = props;

  const schema: yup.Schema<State> = yup.object({
    newPassword1: yup.string()
      .defined('Please enter your new password')
      .min(8, 'Password should be at least 8 characters in length')
      .matches(/^(?=.*[a-z])/, 'Password should contain at least one lower case letter (a-z)')
      .matches(/^(?=.*[A-Z])/, 'Password should contain at least one upper case letter (A-Z)')
      .matches(/^(?=.*[0-9])/, 'Password should contain at least one digit (0-9)')
      .matches(/^(?=.*[!@#\$%\^&\*])/, 'Password should contain at least one special character (!@#$%^&*)')
      .matches(/^(?!.*(.)\1\1.*).*$/, 'Password can not contain more than two characters in a row'),
    newPassword2: yup.string()
      .defined('Please confirm your new password')
      .oneOf([yup.ref('newPassword1')], 'Passwords do not match')
  }); // TODO: complexity check, and make sure new passwords match

  const formik = useFormik<State>({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      newPassword1: '',
      newPassword2: ''
    },
    validationSchema: schema,
    onSubmit: (x) => onChange(x.newPassword1)
  });

  const {
    getHelperText,
    getError
  } = useFormikHelpers<State>({ formik });

  const getTextField = <T extends keyof State>(key: T, label: string): React.ReactNode => (
    <TextField
      type="password"
      label={label}
      name={key}
      value={formik.values[key]}
      onCommit={formik.handleChange}
      onBlur={() => formik.setFieldTouched(key, true, false)}
      required
      helperText={getHelperText(key)}
      error={getError(key)}
    />
  );

  return (
    <RootForm onSubmit={formik.handleSubmit}>
      <Root>
        <WizardHeader title="Change password" />
        <Typography variant="h4">Please enter your new password</Typography>
        <Typography variant="body1">Your new password must contain: a minimum of 8 characters,
          a lower-case and upper-case letter, a number, a special character (eg. @!#$%^&*),
          and not more than 2 identical character in a row.</Typography>
        {getTextField('newPassword1', 'New password')}
        {getTextField('newPassword2', 'Confirm new password')}
        {!!error && (
          <Alert color="error" severity="error">
            {error}
          </Alert>
        )}
        <WizardStepActions
          onCancel={onCancel}
          onNext={formik.submitForm}
          cancelDisabled={formik.isSubmitting}
          nextDisabled={formik.isSubmitting}
        />
      </Root>
    </RootForm>
  );
};

export default PasswordChangeStepThree;
