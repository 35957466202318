import {
  Clock,
  DollarSign,
  File,
  Settings,
} from 'react-feather';
import {
  Dashboard,
  People,
} from '@mui/icons-material';
import {
  AgencyFeature,
  AgencyPermission,
  EnterpriseFeature,
  EnterprisePermission,
  Product,
  UserFeature,
  UserPermission
} from '@oncore/shared';
import FileTextIcon from 'src/assets/img/file-text.svg?react';
import links from './links';
import {
  ContextType,
  Permission,
  Feature
} from './configuration';
import React from 'react';
import { IconProps } from '@oncore/ui/Icon';
import { Optional } from 'utility-types';

export const MENU_ITEM_TYPE_TOP = 'top';
export const MENU_ITEM_TYPE_SUB = 'sub';
export const MENU_ITEM_TYPE_DIV = 'div';

export type MenuItemHref = {
  href: string;
};

export type MenuItemClick = {
  onClick: () => void;
};

export type MenuItemChildren = {
  children: SubMenuItems;
};

export type MenuItemVisible = {
  visible?: boolean | (() => boolean);
};

export type MenuItemPermissions = {
  permissions?: Permission[];
  features?: Feature[];
};

export type MenuItemVisibility = MenuItemVisible | MenuItemPermissions;

export function isMenuItemPermissions(item: MenuItemVisibility): item is MenuItemPermissions {
  return Object.keys(item).includes('permissions');
}

export function isMenuItemFeatures(item: MenuItemVisibility): item is MenuItemPermissions {
  return Object.keys(item).includes('features');
}

export type MenuNode = {
  id: string;
  enabled?: boolean | (() => boolean);
};

export type MenuItemAction = MenuItemHref | MenuItemClick | MenuItemChildren;

export type MenuItem = MenuNode
& (MenuItemAction)
& (MenuItemVisible | MenuItemPermissions)
& {
  title: string | (() => string);
};

export function isMenuItemHref(item: MenuItemAction): item is MenuItemHref {
  return Object.keys(item).includes('href');
}

export function isMenuItemClick(item: MenuItemAction): item is MenuItemClick {
  return Object.keys(item).includes('onClick');
}

export type TopMenuItemAction =
  | MenuItemHref
  | MenuItemClick
  | MenuItemChildren;

export type SpikeMenuItem = {
  to: string;
  icon?: IconProps['variant'];
};

export type SubMenuItemAction =
  | MenuItemHref
  | MenuItemClick
  | MenuItemChildren;

export type SubMenuItem = MenuItem & SubMenuItemAction & {
  type: typeof MENU_ITEM_TYPE_SUB;
  products: Product[] | null;
  spike: SpikeMenuItem;
  contextType?: ContextType | null;
};

export type DivMenuItem = MenuNode & {
  type: typeof MENU_ITEM_TYPE_DIV;
  products: Product[] | null;
  contextType?: ContextType | null;
};

export type GenericTopMenuItem<TIconProps = {}> = MenuItem & TopMenuItemAction & {
  type: typeof MENU_ITEM_TYPE_TOP;
  icon: React.ComponentType<TIconProps>;
  contextType: ContextType | null;
  products: Product[] | null;
  spike: SpikeMenuItem;
  iconProps?: TIconProps;
};

export type SubMenuItems = (SubMenuItem | DivMenuItem)[];

export type ParentTopMenuItem = Omit<GenericTopMenuItem, 'spike'> & MenuItem & {
  children: SubMenuItems;
  spike: Optional<SpikeMenuItem, 'to'>;
};

export type TopMenuItem = GenericTopMenuItem | ParentTopMenuItem;

export type TopMenuItems = (TopMenuItem | DivMenuItem)[];

export type MenuItemType = TopMenuItem | SubMenuItem | DivMenuItem;

export function isMenuItemChildren(item: unknown): item is MenuItemChildren {
  return Object.keys(item as any).includes('children');
}

export function isTopMenuItem<T extends { type: unknown }>(item: MenuItemType | T): item is TopMenuItem {
  return item.type === MENU_ITEM_TYPE_TOP;
}

export function isSubMenuItem<T extends { type: unknown }>(item: MenuItemType | T): item is SubMenuItem {
  return item.type === MENU_ITEM_TYPE_SUB;
}

export function isDivMenuItem<T extends { type: unknown }>(item: MenuItemType | T): item is DivMenuItem {
  return item.type === MENU_ITEM_TYPE_DIV;
}

export function find(items: (TopMenuItem | SubMenuItem | DivMenuItem)[], id: string): TopMenuItem | SubMenuItem | undefined {
  return items.reduce<TopMenuItem | SubMenuItem | undefined>((previousValue, currentValue) => {
    if (!previousValue) {
      if (currentValue.id === id) {
        if (!isDivMenuItem(currentValue)) {
          return currentValue;
        }
      }
      if (isMenuItemChildren(currentValue)) {
        const inner = find(currentValue.children, id);
        if (inner) {
          return inner;
        }
      }
    }
    return previousValue;
  }, undefined);
}

export const EnterpriseLevelMenu = (tenantID: string): TopMenuItems => [
  {
    type: MENU_ITEM_TYPE_TOP,
    id: 'home',
    title: 'Home',
    icon: Dashboard,
    href: links.enterprise.root(tenantID),
    contextType: 'enterprise',
    features: [EnterpriseFeature.SimplexityHomepage],
    products: [Product.Simplexity],
    spike: {
      to: links.enterprise.root(tenantID),
      icon: 'home',
    },
  },
  {
    type: MENU_ITEM_TYPE_TOP,
    id: 'positions',
    icon: People,
    title: 'Positions',
    href: links.enterprise.positions(tenantID),
    contextType: 'enterprise',
    permissions: [EnterprisePermission.PositionUser],
    features: [EnterpriseFeature.Positions],
    products: [Product.Simplexity],
    spike: {
      to: links.enterprise.positions(tenantID),
      icon: 'user-square',
    },
  },
  {
    type: MENU_ITEM_TYPE_TOP,
    id: 'new-placement',
    icon: People,
    title: 'New placement',
    href: links.enterprise.placement.new(tenantID),
    contextType: 'enterprise',
    permissions: [
      EnterprisePermission.PlacementUser,
      EnterprisePermission.PlacementCreate,
    ],
    features: [EnterpriseFeature.Placements],
    products: [Product.Simplexity],
    spike: {
      to: links.enterprise.placement.new(tenantID),
      icon: 'user-plus',
    },
  },
  {
    type: MENU_ITEM_TYPE_TOP,
    id: 'contracts',
    icon: File,
    title: 'Contracts',
    href: links.enterprise.contracts(tenantID),
    contextType: 'enterprise',
    permissions: [EnterprisePermission.ContractUser],
    features: [EnterpriseFeature.Contracts],
    products: [Product.Simplexity],
    spike: {
      to: links.enterprise.contracts(tenantID),
      icon: 'file-attachment',
    },
  },
  {
    type: MENU_ITEM_TYPE_TOP,
    id: 'tenant-timesheets',
    title: 'Timesheets',
    icon: Clock,
    href: links.enterprise.timesheets(tenantID),
    contextType: 'enterprise',
    permissions: [EnterprisePermission.TimesheetUser],
    features: [EnterpriseFeature.Timesheets],
    products: [Product.Simplexity],
    spike: {
      to: links.enterprise.timesheets(tenantID),
      icon: 'clock',
    },
  },
  {
    type: MENU_ITEM_TYPE_TOP,
    id: 'tenant-expenses',
    title: 'Expenses',
    icon: DollarSign,
    href: links.enterprise.expenses(tenantID),
    contextType: 'enterprise',
    permissions: [EnterprisePermission.ExpenseUser],
    features: [EnterpriseFeature.Expenses],
    products: [Product.Simplexity],
    spike: {
      to: links.enterprise.expenses(tenantID),
      icon: 'currency-dollar',
    },
  },
  {
    type: MENU_ITEM_TYPE_TOP,
    id: 'administration',
    title: 'Administration',
    icon: Settings,
    contextType: 'enterprise',
    permissions: [EnterprisePermission.TenantAdmin],
    products: [Product.Simplexity],
    spike: {
      icon: 'bar-chart-square',
    },
    children: [
      {
        type: MENU_ITEM_TYPE_SUB,
        id: 'users',
        title: 'Users',
        href: links.enterprise.admin.users(tenantID),
        products: [Product.Simplexity],
        spike: {
          to: links.enterprise.admin.users(tenantID),
          icon: 'users',
        },
      },
      {
        type: MENU_ITEM_TYPE_SUB,
        id: 'groups',
        title: 'Groups',
        href: links.enterprise.admin.groups(tenantID),
        products: [Product.Simplexity],
        spike: {
          to: links.enterprise.admin.groups(tenantID),
          icon: 'users-2',
        },
      },
      {
        type: MENU_ITEM_TYPE_SUB,
        id: 'organisation',
        title: 'Organisation',
        href: links.enterprise.admin.organisation.root(tenantID),
        features: [EnterpriseFeature.OrganisationalUnits],
        products: [Product.Simplexity],
        spike: {
          to: links.enterprise.admin.organisation.root(tenantID),
          icon: 'building',
        },
      },
      {
        type: MENU_ITEM_TYPE_SUB,
        id: 'locations',
        title: 'Locations',
        href: links.enterprise.admin.locations.root(tenantID),
        products: [Product.Simplexity],
        spike: {
          to: links.enterprise.admin.locations.root(tenantID),
          icon: 'marker-pin',
        },
      },
      {
        type: MENU_ITEM_TYPE_SUB,
        id: 'screenings',
        title: 'Screenings',
        href: links.enterprise.admin.screenings(tenantID),
        products: [Product.Simplexity],
        spike: {
          to: links.enterprise.admin.screenings(tenantID),
          icon: 'user-check',
        },
      },
    ],
  },
];

export const AgencyLevelMenu = (tenantID: string): TopMenuItems => [
  {
    type: MENU_ITEM_TYPE_TOP,
    id: 'agency-contracts',
    icon: File,
    title: 'Contracts',
    href: links.agency.contracts(tenantID),
    contextType: 'agency',
    permissions: [AgencyPermission.ContractsUser],
    features: [AgencyFeature.Contracts],
    products: [Product.Connect],
    spike: {
      to: links.agency.contracts(tenantID),
      icon: 'file-attachment',
    },
  },
  {
    type: MENU_ITEM_TYPE_TOP,
    id: 'agency-administration',
    title: 'Administration',
    icon: Settings,
    contextType: 'agency',
    permissions: [AgencyPermission.AdministrationUser],
    products: [Product.Connect],
    spike: {
      icon: 'bar-chart-square',
    },
    children: [
      {
        type: MENU_ITEM_TYPE_SUB,
        id: 'agency-users',
        title: 'Users',
        href: links.agency.admin.users(tenantID),
        products: null,
        spike: {
          to: links.agency.admin.users(tenantID),
          icon: 'users',
        },
      },
      {
        type: MENU_ITEM_TYPE_SUB,
        id: 'agency-groups',
        title: 'Groups',
        href: links.agency.admin.groups(tenantID),
        products: null,
        spike: {
          to: links.agency.admin.groups(tenantID),
          icon: 'users-2',
        },
      },
    ],
  },
];

export const UserLevelMenu: TopMenuItems = [
  {
    type: MENU_ITEM_TYPE_TOP,
    id: 'user-timesheet-entry',
    icon: Clock,
    title: 'Timesheet entry',
    href: links.user.entry,
    contextType: 'user',
    permissions: [UserPermission.Timesheets],
    features: [UserFeature.Timesheets],
    products: [Product.Freedom],
    spike: {
      to: links.user.entry,
      icon: 'calendar-plus',
    },
  },
  {
    type: MENU_ITEM_TYPE_TOP,
    id: 'user-timesheets',
    title: 'Timesheets',
    icon: Clock,
    href: links.user.timesheets,
    contextType: 'user',
    permissions: [UserPermission.Timesheets],
    features: [UserFeature.Timesheets],
    products: [Product.Freedom],
    spike: {
      to: links.user.timesheets,
      icon: 'clock',
    },
  },
  {
    type: MENU_ITEM_TYPE_TOP,
    id: 'user-expenses',
    title: 'Expenses',
    icon: DollarSign,
    href: links.user.expenses,
    contextType: 'user',
    permissions: [UserPermission.ExpensesUser],
    features: [UserFeature.Expenses],
    products: [Product.Freedom],
    spike: {
      to: links.user.expenses,
      icon: 'currency-dollar',
    },
  },
  {
    type: MENU_ITEM_TYPE_TOP,
    id: 'user-payslips',
    title: 'Payslips',
    icon: File,
    href: links.user.payslips,
    contextType: 'user',
    permissions: [UserPermission.Payslips],
    features: [UserFeature.Payslips],
    products: [Product.Freedom],
    spike: {
      to: links.user.payslips,
      icon: 'receipt',
    },
  },
  {
    type: MENU_ITEM_TYPE_TOP,
    id: 'user-invoices',
    title: 'Invoices',
    icon: FileTextIcon,
    href: links.user.invoices,
    contextType: 'user',
    permissions: [UserPermission.InvoicesUser],
    features: [UserFeature.Invoices],
    products: [Product.Freedom],
    spike: {
      to: links.user.invoices,
      icon: 'receipt-check',
    },
  }
];