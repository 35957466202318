import { useCallback, useState } from 'react';
import { fetchQuery, VariablesOf } from 'relay-runtime';
import { OperationType } from 'relay-runtime/lib/util/RelayRuntimeTypes';
import { GraphQLTaggedNode } from 'relay-runtime/lib/query/RelayModernGraphQLTag';
import environment from 'src/environment';

export type UseQuery<T extends OperationType> = {
  loading: boolean;
  data: T['response'] | null;
  error: Error | null;
  fetch: (variables?: VariablesOf<T>) => Promise<T['response']>;
};

/*
function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
*/

function useQuery<T extends OperationType>(query: GraphQLTaggedNode, queryVariables?: VariablesOf<T>): UseQuery<T> {
  const [loading, setLoading] = useState<boolean>();
  const [data, setData] = useState<T['response'] | null>();
  const [error, setError] = useState<Error | null>(null);

  const fetch = useCallback(async (variablesParam?: VariablesOf<T>): Promise<T['response']> => {
    try {
      setLoading(true);
      const variables = variablesParam || queryVariables;
      const response = await fetchQuery<T>(environment, query, variables || {}).toPromise();

      setData(response);

      return response;
    } catch (e) {
      console.error(e);
      setError(e as Error);
      return undefined;
    } finally {
      setLoading(false);
    }
  }, [query, queryVariables]);

  const handle: UseQuery<T> = {
    loading: Boolean(loading),
    data,
    error,
    fetch,
  };

  return handle;
}

export default useQuery;
