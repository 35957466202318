import { useFloating as a, autoUpdate as n, offset as s, flip as f } from "@floating-ui/react-dom";
const i = 10, r = ({
  flipOptions: t,
  floatingOptions: o,
  offset: e = i
}) => ({
  ...a({
    whileElementsMounted: n,
    middleware: [s(e), f(t)],
    ...o
  })
});
export {
  r as u
};
