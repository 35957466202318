import { LoadingScreen, useEnvironment } from '@oncore/shared';
import { useDispatch, useSelector } from '../../hooks';
import { WebAuth } from 'auth0-js';
import { useEffect } from 'react';
import { logout, setToken } from '../../store/appSlice';
import { Outlet } from 'react-router-dom';

export type Props = {
  children?: never;
};

export const SessionGuard = (_: Props) => {
  const environment = useEnvironment();

  const dispatch = useDispatch();

  const isTokenChecked = useSelector(x => x.app.isTokenChecked);

  const auth = new WebAuth({
    domain: environment.auth?.domain || '',
    clientID: environment.auth?.clientID || '',
    audience: environment.auth?.audience || '',
    redirectUri: `${window.location.origin}/login`,
    responseType: 'code',
    scope: 'openid profile general'
  });

  useEffect(() => {
    if (!isTokenChecked) {
      auth.checkSession({
        responseType: 'token'
      }, (e, x) => {
        if (e || !x?.accessToken) {
          console.debug('[SessionGuard] logging out...', e, x);
          dispatch(logout());
        } else {
          console.debug('[SessionGuard] logging in...');
          dispatch(setToken(x.accessToken));
        }
      });
    }
  }, [isTokenChecked]);

  return !isTokenChecked
    ? (<LoadingScreen />)
    : (<Outlet />);
};

export default SessionGuard;
