import React from 'react';
import { RouteObject } from 'react-router-dom';
import { innerChildRouteFor, layoutFor } from './helpers';
import AuthGuard from './AuthGuard';
import ScrollReset from '../ScrollReset';
import SessionGuard from './SessionGuard';
import { AppState } from 'src/store/appSlice';
import Chrome from 'src/spike/Chrome';

type GetProtectedRoutesParams = Pick<AppState, 'isBankingEnabled' | 'isCompanyDetailsEnabled'>;

const CommonProfileRoot = React.lazy(() => import('src/pages/user/Profile/Profile'));
const CommonProfileRedirect = React.lazy(() => import('src/pages/user/Profile/Redirect'));
const CommonProfileDetails = React.lazy(() => import('src/pages/user/Profile/Details'));
const CommonProfileSecurity = React.lazy(() => import('src/pages/user/Profile/Security'));
const CommonProfileTenants = React.lazy(() => import('src/pages/user/Profile/Tenants'));
const CommonProfileLegalName = React.lazy(() => import('src/pages/user/Profile/LegalName'));
const CommonProfileBankDetails = React.lazy(() => import('src/pages/user/Profile/Banking'));
const CommonProfileCompanyDetails = React.lazy(() => import('src/pages/user/Profile/Company'));
const CommonEmailChange = React.lazy(() => import('src/auth/EmailChange'));
const CommonPasswordChange = React.lazy(() => import('src/auth/PasswordChange'));
const MfaEnroll = React.lazy(() => import('src/auth/MfaEnroll/MultiFactorAuthEnrollPage'));
const MfaDisable = React.lazy(() => import('src/auth/MfaDisable/MultiFactorAuthDisable'));

export const getProtectedRoutes = (params: GetProtectedRoutesParams): RouteObject[] => {

  const profileChildren = [
    innerChildRouteFor('details', <CommonProfileDetails />),
    innerChildRouteFor('tenants', <CommonProfileTenants />),
    innerChildRouteFor('security', <CommonProfileSecurity />),
    innerChildRouteFor('change-legal-name', <CommonProfileLegalName />)
  ];

  if (params.isBankingEnabled) {
    profileChildren.push(innerChildRouteFor('bank-details', <CommonProfileBankDetails />));
  }
  if (params.isCompanyDetailsEnabled) {
    profileChildren.push(innerChildRouteFor('company-details', <CommonProfileCompanyDetails />));
  }

  return [{
    element: (<SessionGuard />),
    children: [{
      path: 'change-email',
      element: (
        <React.Suspense>
          <CommonEmailChange />
        </React.Suspense>
      )
    },
    {
      path: 'change-password',
      element: (
          <React.Suspense>
            <CommonPasswordChange />
          </React.Suspense>
      )
    },
    {
      Component: AuthGuard,
      children: [{
        path: 'enroll-mfa',
        element: (
            <React.Suspense>
              <MfaEnroll />
            </React.Suspense>
        )
      }, {
        path: 'disable-mfa',
        element: (
            <React.Suspense>
              <MfaDisable />
            </React.Suspense>
        )
      }]
    }, {
      path: 'profile',
      Component: AuthGuard,
      children: [{
        element: (
            <React.Suspense>
              <ScrollReset />
              <Chrome />
            </React.Suspense>
        ),
        children: [
          layoutFor(<CommonProfileRoot />, profileChildren), {
            index: true,
            element: <CommonProfileRedirect />
          }]
      }]
    }]
  }];
};

