import { RouteObject } from 'react-router-dom';
import LoginWidget from 'src/auth/Login';
import Logout from 'src/auth/Logout';
import PasswordReset from 'src/auth/PasswordReset';

export const getGeneralRoutes = (): RouteObject[] => [{
  path: 'login',
  element: (<LoginWidget />)
}, {
  path: 'logout',
  element: (<Logout />)
}, {
  path: 'reset-password',
  element: (<PasswordReset />)
}];
