import React from 'react';
import { StyledLinearProgress } from '@oncore/shared';
import {
  AppState,
  isTenantContext,
  isUserContext,
} from 'src/store/appSlice';
import TenantContextSwitch from './TenantContextSwitch';
import { getUserRoutes } from './getUserRoutes';
import { getAgencyRoutes } from './getAgencyRoutes';
import { getEnterpriseRoutes } from './getEnterpriseRoutes';

const getContextDependentRoutes = (params: Pick<AppState, 'currentContext' | 'contexts'>) => {
  const {
    currentContext,
    contexts,
  } = params;
  const routeItems = [];

  if (currentContext.context === 'user') {
    if (contexts.find(isTenantContext)) {
      // Current context is user context, but there are tenant context available, too
      routeItems.push({
        path: ':tenantID/*',
        element: (
          <React.Suspense fallback={<StyledLinearProgress />}>
            <TenantContextSwitch />
          </React.Suspense>
        ),
      });
    }
    routeItems.push(getUserRoutes(currentContext));
  } else {
    const userContext = contexts.find(isUserContext);
    if (userContext) {
      routeItems.push(getUserRoutes(userContext));
    }
    if (currentContext.context === 'agency') {
      routeItems.push(getAgencyRoutes(currentContext));
    }
    if (currentContext.context === 'enterprise') {
      routeItems.push(getEnterpriseRoutes(currentContext));
    }
  }

  return routeItems;
};

export default getContextDependentRoutes;
