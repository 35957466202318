import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

import Dropdown, {
  DropdownItems,
  DropdownItem,
} from '@oncore/ui/Dropdown';
import {
  getAppStateSelector,
} from 'src/store/selectors';
import links from 'src/components/chrome/links';

type WorkspaceOptionItem = {
  id: string;
  displayName: string;
  onClick: () => void;
};

const StyledDropdown = styled(Dropdown)`
  width: 100%;
`;

const WorkspaceSwitcher = () => {
  const {
    contexts,
    currentContext,
    displayName,
    userID,
  } = useSelector(getAppStateSelector());
  const navigate = useNavigate();
  const optionList = useMemo(() => {

    if (!userID) {
      return [];
    }

    const options = contexts.reduce((accList, item) => {
      switch (item.context) {
        case 'user':
          return [
            ...accList,
            {
              id: userID,
              displayName: displayName,
              onClick: () => navigate(links.user.root),
            }
          ];
        case 'agency':
          return [
            ...accList,
            {
              id: item.id,
              displayName: item.displayName,
              onClick: () => navigate(links.agency.root(item.id)),
            }
          ];
        case 'enterprise':
          return [
            ...accList,
            {
              id: item.id,
              displayName: item.displayName,
              onClick: () => navigate(links.enterprise.root(item.id)),
            }
          ];
        default:
          return accList;
      }
    }, [] as WorkspaceOptionItem[]);

    return options.filter((item) => {

      if ('id' in currentContext) {
        return item.id !== currentContext.id;
      }

      if (currentContext.context === 'user') {
        return item.id !== userID;
      }

      return true;
    });
  }, [contexts]);
  const contextDisplayName = useMemo(() => {

    if ('displayName' in currentContext) {
      return currentContext.displayName;
    }

    if (currentContext.context === 'user') {
      return displayName;
    }

    return '';
  }, [currentContext, displayName]);

  if (!contextDisplayName) {
    return null;
  }

  return (
    <StyledDropdown
      variant="select"
      placement="top"
      selectProps={{
        text: contextDisplayName,
        rightIcon: {
          variant: 'chevron-selector-vertical',
        },
      }}
      disabled={optionList.length === 0}>
      <DropdownItems>
        {
          optionList.map((item) => (
            <DropdownItem
              key={item.id}
              text={item.displayName}
              onClick={item.onClick}/>
          ))
        }
      </DropdownItems>
    </StyledDropdown>
  );
};

export default WorkspaceSwitcher;
