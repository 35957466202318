import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CodeEntry, TextField, UnableToAuthenticateBlock, WizardStepActions } from '@oncore/shared';
import { EXPECTED_CODE_LENGTH } from 'src/constants';
import { useSecurity } from 'src/hooks';

const Root = styled.div`
  width: 380px;
  main {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 24px;
    padding: 24px 16px;
    counter-reset: css-counter 0;
    
    gap: 24px;

    padding: 24px 16px;

    > span:first-child {
      font-size: 20.8px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      text-align: center;
    }
    
    > span.error {
      color: #FF5E52;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
    }
  }
`;

export type Props = {
  token: string;
  onNext: (token: string) => void;
  onCancel: () => void;
  children?: never;
};

export const VerifyMFA = (props: Props) => {

  const { token, onNext, onCancel } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [invalid, setInvalid] = useState<boolean>(false);
  const [usingRecoveryCode, setUsingRecoveryCode] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');

  const { onVerifyMFA } = useSecurity();

  useEffect(() => {
    setInvalid(false);
  }, [code]);

  const CodeEntryWidget = usingRecoveryCode ? (
    <>
      <span>Enter your recovery code</span>
      <TextField
        autoFocus
        fullWidth
        variant="filled"
        label="Recovery code"
        value={code}
        error={invalid}
        style={{ maxWidth: '350px' }}
        onBlur={(x: any) => setCode(x.target.value)}
        onChange={(x: any) => setCode(x.target.value)}
      />
      {invalid && (
        <span className="error">Recovery code is incorrect, please try again!</span>
      )}
    </>
  ) : (
    <>
      <span>Enter your OTP code</span>
      <CodeEntry
        autoFocus
        error={invalid}
        length={EXPECTED_CODE_LENGTH}
        onChange={setCode}
      />
      {invalid && (
        <span className="error">One-time passcode is incorrect, please try again!</span>
      )}
    </>
  );

  const handleOnNext = () => {
    setLoading(true);
    onVerifyMFA(token, code, usingRecoveryCode)
      .then((access_token) => {
        onNext(access_token);
        setLoading(false);
      })
      .catch((e: Error) => {
        if (usingRecoveryCode) {
          console.error('[PasswordChangePage] Recovery code verification error', e);
        } else {
          console.error('[PasswordChangePage] PIN verification error', e);
        }
        setInvalid(true);
        setLoading(false);
      });
  };

  const handleFormKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleOnNext();
    }
  };

  return (
    <Root>
      <form onKeyDown={handleFormKeyDown}>
        <main>
          {CodeEntryWidget}
          <UnableToAuthenticateBlock
            usingRecoveryCode={usingRecoveryCode}
            onEnterRecoveryCode={() => {
              setUsingRecoveryCode(true);
              setCode('');
            }}
          />
        </main>
        <WizardStepActions
          onNext={handleOnNext}
          onCancel={onCancel}
          nextDisabled={loading || (usingRecoveryCode ? code.length <= 0 : code.length != EXPECTED_CODE_LENGTH)}
          cancelDisabled={loading}
        />
      </form>
    </Root>
  );
};
