import { Alert, Typography } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { TextField, WizardStepActions } from '@oncore/shared';
import { useFormikHelpers } from 'src/hooks';

const Root = styled.div`
  width: 380px;
  main {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 24px;
    padding: 24px 16px;
    counter-reset: css-counter 0;
  }
`;

export type Values = {
  password: string,
};

export type VerifyPasswordResult = {
  state: 'success';
} | {
  state: 'mfa_required';
  token: string;
};

export type Props = {
  onNext: (password: string) => Promise<void>;
  onCancel: () => void;
  header?: string;
  error?: Error;
  children?: never;
};

export const VerifyPassword = (props: Props) => {

  const {
    error,
    onNext,
    onCancel,
    header = 'Please enter your password'
  } = props;

  const schema: yup.Schema<Values> = yup.object({
    password: yup.string()
      .label('Password')
      .defined('Your password is required')
  });

  const formik = useFormik<Values>({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      password: ''
    },
    validationSchema: schema,
    onSubmit: (values: Values, formikHelpers) => {
      onNext(values.password)
        .then(() => formikHelpers.setSubmitting(false));
    }
  });

  const { getHelperText, getError } = useFormikHelpers<Values>({ formik });

  return (
    <Root>
      <form onSubmit={formik.handleSubmit}>
        <main>
          <Typography variant="h4">{header}</Typography>
          <TextField
            label="Password"
            id="password"
            name="password"
            type="password"
            value={formik.values.password}
            onCommit={formik.handleChange}
            onBlur={() => formik.setFieldTouched('password', true, false)}
            helperText={getHelperText('password')}
            error={getError('password')}
            disabled={formik.isSubmitting}
            required
          />
          {!!error && (
            <Alert color="error" severity="error">
              {error.message}
            </Alert>
          )}
        </main>
        <WizardStepActions
          onCancel={onCancel}
          cancelDisabled={formik.isSubmitting}
          nextDisabled={formik.isSubmitting}
        />
      </form>
    </Root>
  );
};

export default VerifyPassword;
