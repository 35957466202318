/**
 * @generated SignedSource<<3064590475c91bb89d3f006aece09b6d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ValidationSeverity = "error" | "warning";
export type ValidationStatus = "error" | "ok" | "warning";
export type PasswordChangeMutation$variables = {
  password: string;
};
export type PasswordChangeMutation$data = {
  readonly result: {
    readonly items: ReadonlyArray<{
      readonly message: string;
      readonly severity: ValidationSeverity;
    }> | null;
    readonly status: ValidationStatus;
  };
};
export type PasswordChangeMutation = {
  response: PasswordChangeMutation$data;
  variables: PasswordChangeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "password"
  }
],
v1 = [
  {
    "alias": "result",
    "args": [
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      }
    ],
    "concreteType": "Validation",
    "kind": "LinkedField",
    "name": "updateUserPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationItem",
        "kind": "LinkedField",
        "name": "items",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "severity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PasswordChangeMutation",
    "selections": (v1/*: any*/),
    "type": "GeneralMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PasswordChangeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "743d3d244325647dcb6418245068f42e",
    "id": null,
    "metadata": {},
    "name": "PasswordChangeMutation",
    "operationKind": "mutation",
    "text": "mutation PasswordChangeMutation(\n  $password: String!\n) {\n  result: updateUserPassword(password: $password) {\n    status\n    items {\n      severity\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8ad8e7c68e1be8082332f1863b913133";

export default node;
