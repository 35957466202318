import {
  PropsWithChildren,
  useEffect,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { getEnvironmentSelector } from 'src/state/environment/selectors';
import { setEnvironmentRoutine } from 'src/state/environment/reducer';
import { SplashScreen } from '@oncore/shared';

export type LoaderProps = PropsWithChildren;

function Loader(props: LoaderProps) {

  const { isReady } = useSelector(getEnvironmentSelector());

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isReady) {
      dispatch(setEnvironmentRoutine.trigger());
    }
  }, []);

  if (!isReady) {
    return (
      <SplashScreen/>
    );
  }

  return props.children;
}

export default Loader;
