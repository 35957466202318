import { LoadingScreen, WizardPage } from '@oncore/shared';
import { Navigate } from 'react-router-dom';
import LoginWidget from './LoginWidget';
import useLogin from './useLogin';

export type Props = {
  children?: never;
};

const LoginPage = (_: Props) => {

  const {
    code,
    token,
    error,
    from,
    onLogin
  } = useLogin();

  return !token
    ? code
      ? (<LoadingScreen />)
      : (
        <WizardPage title="Login">
          <LoginWidget error={error} onLogin={onLogin} />
        </WizardPage>
      )
    : <Navigate to={from} state={from.state} />;
};

export default LoginPage;
