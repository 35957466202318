import { graphql } from 'react-relay';

export const profileEmailQuery = graphql`
    query ProfileEmailQuery {
        user {
            profile: profileEx {
                email
            }
        }
    }
`;
