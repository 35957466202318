import { WebAuth } from 'auth0-js';
import {
  AnalyticsEvents,
  useAnalyticsUpdater,
  useEnvironment
} from '@oncore/shared';
import { useDispatch } from 'src/hooks';
import { logout } from 'src/store/appSlice';
import { useEffect } from 'react';

export type Props = {
  children?: never;
};

const Logout = (_: Props) => {

  const dispatch = useDispatch();

  const environment = useEnvironment();

  const sendAnalyticsLogout = useAnalyticsUpdater(AnalyticsEvents.LOGOUT);

  const auth = new WebAuth({
    domain: environment.auth?.domain || '',
    clientID: environment.auth?.clientID || '',
    audience: environment.auth?.audience || '',
  });

  useEffect(() => {
    sendAnalyticsLogout();
    auth.logout({
      returnTo: window.location.origin
    });

    dispatch(logout());
  }, []);

  return null;
};

export default Logout;
