import styled from 'styled-components';
import { Link, Typography } from '@mui/material';
import { links } from 'src/components/chrome';
import { WizardHeader } from '@oncore/shared';

const Root = styled.div`
  display: flex;
  position: relative;
  flex-flow: column;
  align-items: center;
  max-width: 350px;
  flex-basis: 100%;
  margin: 0 24px;

  > * {
    margin: 8px 0;
  }

  > svg {
    position: absolute;
    top: 35%;
    left: 18%;
    bottom: -25%;
    right: -50%;
    color: white;
    fill: white;
  }

  > img {
    z-index: 1;
    margin: 48px 0;
  }

  > h4, p {
    z-index: 1;
    align-self: center;
  }

  > a {
    z-index: 1;
    margin-top: 16px;
    align-self: flex-start;
  }

  > button {
    z-index: 1;
    margin-top: 16px;
    align-self: flex-end;
  }

  .MuiInputBase-root {
    background-color: #E9EDF1 !important;
  }
`;

export type Props = {
  children?: never;
};

const PasswordResetSent = (_: Props) => {
  return (
    <Root>
      <WizardHeader title="Reset Password" />
      <Typography variant="h4">Password reset sent!</Typography>
      <Typography variant="body1">Please check your email and follow the instructions.</Typography>
      <Link
        variant="body2"
        underline="none"
        href={links.login}
      >Return to login</Link>
    </Root>
  );
};

export default PasswordResetSent;
