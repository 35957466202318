import { j as e } from "./jsx-runtime-DmTjM30f.js";
import m from "styled-components";
import { c as j } from "./clsx.m-BMVuQnLY.js";
import { i as A } from "./isExhaustive-RFXKPwPh.js";
import { A as X } from "./AvatarLabelGroup-C4zzKo4w.js";
import "./styles-CuuuEl0j.js";
import { T as b } from "./Typography-DPoSDQ1T.js";
import { getPaletteForeground as B, getSpacings as y, getRadii as S, getPaletteBackground as g, getPaletteBorder as V, getPaletteBase as P } from "./components/Theme/utils.es.js";
import { createPortal as E } from "react-dom";
import { Menu as h } from "@headlessui/react";
import { A as Y } from "./Avatar-B5kFEaeY.js";
import "./components/Theme/themes.es.js";
import "./palettes-Dfhobg_2.js";
import { useRef as Z, useMemo as _ } from "react";
import "./CssBaseline-E-zwSHrJ.js";
import { I as M } from "./Icon-BJLMcRlb.js";
import "./components/Icon/countryVariants.es.js";
import { Items as T } from "./components/Dropdown/style.es.js";
import { B as ee } from "./Button-iQ12_5TW.js";
import { u as te } from "./useDisclose-DpzoV9h_.js";
import { u as W } from "./useAppFloating-CuhQeRN4.js";
const re = m.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: 100%;
`, ne = m.div`
  flex: 1;
  text-align: left;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`, R = m(M)`
  color: ${B("quarterary.default")};
`, H = (r) => {
  const {
    text: n,
    shortcut: t,
    leftIcon: s,
    rightIcon: o,
    className: c
  } = r, i = {
    width: 16,
    height: 16
  };
  return /* @__PURE__ */ e.jsxs(re, { className: c, children: [
    s && /* @__PURE__ */ e.jsx(
      R,
      {
        ...i,
        ...s
      }
    ),
    /* @__PURE__ */ e.jsxs(ne, { children: [
      /* @__PURE__ */ e.jsx(b, { variant: "text", size: "sm", weight: "medium", as: "span", className: "text", children: n }),
      t && /* @__PURE__ */ e.jsx(b, { variant: "text", size: "xs", weight: "regular", as: "span", color: "tertiary", children: t })
    ] }),
    o && /* @__PURE__ */ e.jsx(
      R,
      {
        ...i,
        ...o
      }
    )
  ] });
}, se = m.button`
  margin: ${y("xxs")} ${y("sm")};

  flex: 1;

  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: ${S("sm")};

  padding: 9px 10px;

  border: none;

  color: ${B("quarterary.default")};

  background-color: ${g("primary.default")};

  overflow: hidden;

  outline: none;

  background-color: ${(r) => r.$isActive ? g("primary.hover") : "inherit"};

  &:not(.disabled):hover {
    cursor: pointer;
    background-color: ${g("primary.hover")};
  }
`, z = (r) => {
  const {
    selected: n,
    disabled: t,
    onClick: s,
    className: o,
    ...c
  } = r, i = j(
    {
      selected: n,
      disabled: t
    },
    o
  );
  return /* @__PURE__ */ e.jsx(h.Item, { disabled: t, children: ({ active: p }) => /* @__PURE__ */ e.jsx(
    se,
    {
      onClick: s,
      $isActive: p,
      className: i,
      children: /* @__PURE__ */ e.jsx(H, { ...c })
    }
  ) });
}, oe = m.div`
  z-index: 10;
  
  display: flex;
  flex-direction: column;
  
  padding: 4px 0;
    
  &:not(:first-child) {
    border-top: 1px solid ${V("secondary")};
  }
`, ie = (r) => {
  const {
    className: n,
    children: t,
    ...s
  } = r;
  return /* @__PURE__ */ e.jsx(oe, { ...s, className: n, children: t });
}, O = m.header`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  
  padding: 12px 16px;

  border-bottom: 1px solid ${V("secondary")};
`, Pe = (r) => {
  switch (r.variant) {
    case "avatar": {
      const {
        variant: n,
        avatar: {
          size: t,
          ...s
        },
        className: o,
        ...c
      } = r;
      return /* @__PURE__ */ e.jsx(O, { ...c, className: j(`variant-${n}`, o), children: /* @__PURE__ */ e.jsx(X, { ...s, size: t ?? "md" }) });
    }
    case "text": {
      const {
        variant: n,
        text: t,
        className: s,
        ...o
      } = r;
      return /* @__PURE__ */ e.jsx(O, { ...o, className: j(`variant-${n}`, s), children: /* @__PURE__ */ e.jsx(b, { variant: "text", size: "sm", weight: "semibold", as: "span", children: t }) });
    }
    default:
      A(r);
  }
}, ae = (r) => {
  const {
    currentValue: n,
    selectedValue: t,
    isMultiple: s
  } = r;
  if (!s)
    return t;
  const o = n.split(",").filter((i) => !!i);
  return o.find((i) => i === t) ? [
    ...o
  ].filter((i) => i !== t).join(",") : [
    ...o,
    t
  ].join(",");
}, le = m(ie)`
  &:not(:first-child) {
    border: none;
  }
  .selected {
    svg {
      color: ${B("brand.primary.default")};
    }
  }
`, ce = m.input`
  display: none;
`, de = (r) => {
  const {
    label: n,
    value: t,
    options: s,
    className: o,
    placement: c,
    renderOption: i,
    onChange: p,
    inputRef: x,
    isMultiple: f,
    ...$
  } = r, {
    isOpen: w,
    onClose: I,
    onOpen: q
  } = te(!1), {
    refs: C,
    floatingStyles: L
  } = W({
    floatingOptions: {
      placement: c,
      open: !1
    }
  }), v = Z(null), D = _(() => {
    if (f) {
      const a = [
        ...new Set(t.split(","))
      ];
      if (a.length === 1) {
        const [u] = a, d = s.find((N) => N.value === u);
        return d ? `: ${d.label}` : "";
      }
      if (a.length > 1)
        return ` (${a.length})`;
    }
    const l = s.find((a) => a.value === t);
    return l ? `: ${l.label}` : "";
  }, [
    t,
    s
  ]), G = s.length === 0, J = typeof i < "u", K = (l) => f ? t.split(",").findIndex((u) => u === l) !== -1 : l === t, F = (l, a) => {
    var d;
    const u = (d = Object.getOwnPropertyDescriptor(
      HTMLInputElement.prototype,
      "value"
    )) == null ? void 0 : d.set;
    if (v.current && u) {
      const N = ae({
        currentValue: t,
        selectedValue: a,
        isMultiple: f
      });
      u.call(v.current, N);
      const U = new Event("input", {
        bubbles: !0
      });
      v.current.dispatchEvent(U);
    }
    f && l.preventDefault();
  }, Q = () => {
    w ? I() : q();
  };
  return /* @__PURE__ */ e.jsxs(h, { as: le, children: [
    /* @__PURE__ */ e.jsx(
      h.Button,
      {
        as: ee,
        className: o,
        ref: C.setReference,
        onClick: Q,
        ...$,
        children: typeof n == "string" ? /* @__PURE__ */ e.jsxs(b, { size: "sm", color: "none", weight: "semibold", children: [
          n,
          D
        ] }) : /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
          n,
          /* @__PURE__ */ e.jsx(b, { size: "sm", color: "none", weight: "semibold", children: D })
        ] })
      }
    ),
    /* @__PURE__ */ e.jsx(
      ce,
      {
        ref: (l) => {
          typeof x == "function" ? x(l) : x && (x.current = l), v.current = l;
        },
        value: t,
        onChange: p
      }
    ),
    E(
      /* @__PURE__ */ e.jsx(T, { style: L, ref: C.setFloating, children: G ? /* @__PURE__ */ e.jsx(z, { disabled: !0, text: "No data" }) : /* @__PURE__ */ e.jsx(e.Fragment, { children: s.map(({ label: l, value: a }) => {
        const u = K(a);
        return J ? /* @__PURE__ */ e.jsx(
          h.Item,
          {
            as: "div",
            onClick: (d) => F(d, a),
            children: ({ active: d }) => i({ label: l, value: a, isSelected: u, isActive: d })
          },
          a
        ) : /* @__PURE__ */ e.jsx(
          z,
          {
            text: l,
            selected: u,
            onClick: (d) => F(d, a),
            rightIcon: u ? { variant: "check", color: "red" } : void 0
          },
          a
        );
      }) }) }),
      document.body
    )
  ] });
}, k = m(h.Button)`
  &:not(.disabled) {
    cursor: pointer;
  }

  padding: 0;
  border-style: none;
  background: ${g("primary.default")};

  &.variant-avatar {
    color: ${P("black")};

    &:hover {
      color: ${P("black")};
    }

    outline: none;
  }

  &.variant-select {
    padding: ${y("md")} ${y("lg")};
    border-radius: ${S("sm")};

    &:hover {
      background: ${g("primary.hover")};
    }
  }

  &.variant-menu {
    border-radius: ${S("full")};
    
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    
    &:hover {
      box-shadow: 0 0 0 4px rgba(152, 162, 179, 0.14);
    }
  }

  width: ${(r) => r.$isFullWidth ? "100%" : ""};
`, Re = (r) => {
  const {
    variant: n,
    disabled: t,
    children: s,
    className: o,
    fullWidth: c,
    placement: i = "bottom-end"
  } = r, {
    refs: p,
    floatingStyles: x
  } = W({
    floatingOptions: {
      placement: i
    }
  }), f = j(`variant-${n}`, o), $ = () => {
    switch (n) {
      case "avatar": {
        const { size: w, ...I } = r.avatarProps;
        return /* @__PURE__ */ e.jsx(
          k,
          {
            ref: p.setReference,
            className: f,
            disabled: t,
            children: /* @__PURE__ */ e.jsx(Y, { size: w ?? "md", ...I })
          }
        );
      }
      case "menu":
        return /* @__PURE__ */ e.jsx(
          k,
          {
            ref: p.setReference,
            className: f,
            disabled: t,
            children: /* @__PURE__ */ e.jsx(M, { variant: "kebab" })
          }
        );
      case "select":
        return /* @__PURE__ */ e.jsx(
          k,
          {
            className: f,
            ref: p.setReference,
            $isFullWidth: c,
            disabled: t,
            children: /* @__PURE__ */ e.jsx(H, { ...r.selectProps })
          }
        );
      case "filter":
        return /* @__PURE__ */ e.jsx(de, { placement: i, ...r.filterSelectProps });
      default:
        A(n);
    }
  };
  return /* @__PURE__ */ e.jsxs(h, { children: [
    $(),
    n === "filter" ? void 0 : /* @__PURE__ */ e.jsx(e.Fragment, { children: E(
      /* @__PURE__ */ e.jsx(
        T,
        {
          ref: p.setFloating,
          style: x,
          $isFullWidth: c,
          children: s
        }
      ),
      document.body
    ) })
  ] });
};
export {
  z as D,
  ie as a,
  Pe as b,
  Re as c,
  de as d
};
