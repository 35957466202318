import * as yup from 'yup';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { Alert, Link, Typography } from '@mui/material';
import { MonogramCurrentColor, TextField, TrackedButton } from '@oncore/shared';
import Logo from 'src/assets/img/welcome-logo.svg';
import { links } from 'src/components/chrome';

const RootForm = styled.form`
  display: flex;
  position: relative;
  flex-flow: column;
  align-items: center;
  max-width: 350px;
  flex-basis: 100%;
  margin: 0 24px;

  > * {
    margin: 8px 0;
  }

  > a {
    z-index: 1;
    margin-top: 16px;
    align-self: flex-start;
  }

  > button {
    z-index: 1;
    margin-top: 16px;
    align-self: flex-end;
  }

  > h4 {
    z-index: 1;
    align-self: flex-start;
  }

  > svg {
    position: absolute;
    top: 35%;
    left: 18%;
    bottom: -25%;
    right: -50%;
    color: white;
    fill: white;
  }

  > img {
    z-index: 1;
    margin: 48px 0;
  }
`;

const Root = styled.div`
  max-width: 350px;
  flex-basis: 100%;
  
  > * {
    margin: 8px 0;
  }
  
  .MuiInputBase-root {
    background-color: #E9EDF1 !important;
  }
  
  .MuiPaper-root {
    z-index: 1;
    align-self: stretch;
  }
`;

export type Values = {
  email: string,
  password: string
};

export type Props = {
  error: string | null;
  onLogin: (username: string, password: string) => Promise<void>;
  children?: never;
};

const LoginWidget = ({ error, onLogin }: Props) => {

  const schema: yup.Schema<Values> = yup.object({
    email: yup.string()
      .label('Email')
      .defined()
      .required('Your login email is required')
      .email(),
    password: yup.string()
      .label('Password')
      .defined('Your password is required')
  });

  const formik = useFormik<Values>({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: schema,
    onSubmit: (values: Values, formikHelpers) => {
      onLogin(values.email, values.password)
        .then(() => formikHelpers.setSubmitting(false));
    }
  });

  return (
    <Root>
      <RootForm onSubmit={formik.handleSubmit}>
        <MonogramCurrentColor />
        <img alt="" src={Logo} />
        <Typography variant="h4">Log in</Typography>
        <TextField
          label="Email"
          id="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          helperText={formik.touched.email ? formik.errors.email : undefined}
          error={formik.touched.email && !!formik.errors.email}
          disabled={formik.isSubmitting}
          required
        />
        <TextField
          label="Password"
          id="password"
          name="password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          helperText={formik.touched.password ? formik.errors.password : undefined}
          error={formik.touched.password && !!formik.errors.password}
          disabled={formik.isSubmitting}
          required
        />
        {!!error && (
          <Alert color="error" severity="error">
            {error}
          </Alert>
        )}
        <Link
          variant="body2"
          underline="none"
          href={links.resetPassword}
        >Forgot password?</Link>
        <TrackedButton
          trackedName="Log in"
          variant="contained"
          disabled={formik.isSubmitting}
          onClick={formik.submitForm}
          type="submit"
        >Log in</TrackedButton>
      </RootForm>
    </Root>
  );
};

export default LoginWidget;
