
export type EnterprisePositionsNavigationContext = {
  params: {
    tenantID: string;
  };
};

const enterprisePositionsLink = (params: EnterprisePositionsNavigationContext['params']) => {
  return `/${params.tenantID}/positions` as const;
};

export const enterprisePositionsPath = enterprisePositionsLink({
  tenantID: ':tenantID',
});

export default enterprisePositionsLink;
