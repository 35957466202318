import {
  AnalyticsEvents,
  useAnalyticsUpdater,
  useEnvironment,
  useSetAnalyticsUserID
} from '@oncore/shared';
import { useDispatch, useSelector } from '../../hooks';
import { useLocation, Location } from 'react-router-dom';
import { Auth0Result, WebAuth } from 'auth0-js';
import links from '../../components/chrome/links';
import { useEffect, useState } from 'react';
import { logout, setToken } from '../../store/appSlice';
import jwtDecode, { JwtPayload } from 'jwt-decode';

export const useLogin = (): {
  code: string | null;
  token: string | null;
  error: string | null;
  from: Location;
  onLogin: (username: string, password: string) => Promise<void>;
} => {

  const redirectUri = `${window.location.origin}${links.login}`;

  const environment = useEnvironment();
  const token = useSelector(x => x.app.token);
  const dispatch = useDispatch();
  const location = useLocation();

  const [error, setError] = useState<string | null>(null);

  const auth = new WebAuth({
    domain: environment.auth?.domain || '',
    clientID: environment.auth?.clientID || '',
    audience: environment.auth?.audience || '',
    redirectUri,
    responseType: 'code',
    scope: 'openid profile general'
  });

  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');
  const state = params.get('state');

  const from = (location.state as Location)
    || (state && JSON.parse(atob(state)) as Location)
    || ({ pathname: '/' });

  const sendAnalytics = useAnalyticsUpdater(AnalyticsEvents.LOGIN);
  const setAnalyticsUserID = useSetAnalyticsUserID();

  useEffect(() => {
    if (!token) {
      if (code) {
        auth.client.oauthToken(
          {
            code: code,
            grantType: 'authorization_code',
            redirectUri,
          },
          (err, data: Auth0Result) => {
            if (err) {
              dispatch(logout());
              // setToken('');
              console.warn('oauthToken', err);
            } else {
              sendAnalytics();
              try {
                setAnalyticsUserID(jwtDecode<JwtPayload>(data.accessToken || '').sub || '');
              } catch {
                console.warn('[data.accessToken] Decoded token user id was not found, user ID will not be set');
              }
              dispatch(setToken(data.accessToken || ''));
            }
          }
        );
      }
    }
  }, [code, token]);

  const onLogin = (username: string, password: string): Promise<void> => {
    return new Promise<void>((resolve) => {
      auth.login({
        realm: 'OncoreFlex',
        username,
        password,
        state: btoa(JSON.stringify(from))
      }, (e) => {
        if (e?.error_description) {
          setError(e.error_description);
        } else {
          setError(null);
        }
        resolve();
      });
    });
  };

  return {
    code,
    token,
    error,
    from,
    onLogin
  };
};

export default useLogin;
