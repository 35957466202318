import React from 'react';
import { useSelector } from 'src/hooks';
import { useLocation } from 'react-router-dom';
import { links } from '../chrome';
import { LoadingScreen, RouterRedirect } from '@oncore/shared';

const NotFoundView = React.lazy(() => import('src/spike/Notice/NotFoundErrorNotice'));

export type Props = {
  children?: never;
};

export const ContextGuard = (_: Props) => {

  const location = useLocation();

  const { currentContext } = useSelector((s) => s.app);

  switch (currentContext.context) {
    case 'user':
      return location.pathname === '/'
        ? (<RouterRedirect to={links.user.root} />)
        : (<NotFoundView />);

    case 'agency':
      return location.pathname === '/'
        ? (<RouterRedirect to={links.agency.root(currentContext.id)} />)
        : (<NotFoundView />);

    case 'enterprise':
      return location.pathname === '/'
        ? (<RouterRedirect to={links.enterprise.root(currentContext.id)} />)
        : (<NotFoundView />);

    case 'loading':
      return (<LoadingScreen />);

    case 'empty':
    case 'error':
    default:
      return (<NotFoundView />);
  }
};

export default ContextGuard;
